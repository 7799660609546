export const facts = [
  `
    I used to play chess semi-competitively in high school. However, I stopped playing for a while
    once I got to university, but I now play it again but this time only casually against bots
    and in puzzles on chess.com. I don't really have a favorite opening but I usually play the
    Ruy Lopez or Scotch opening, some variation of the four knights opening (classic
    beginner's opening) or if I'm playing black, the sicilian defense. I would count myself as a
    pretty aggressive player albeit a slow one.
    `,
  `
    Coming soon...
    `,
  `
    Coming soon...
    `,
  `
    Coming soon...
    `,
  `
    Coming soon...
    `,
];
