export const skills = {
  web: [
    'JavaScript',
    'TypeScript',
    'React',
    'Redux',
    'RTK Query',
    'Axios',
    'Apollo',
    'Jest',
    'React Testing Library',
  ],
  android: [
    'Kotlin',
    'Java',
    'Gradle',
    'Jetpack Compose',
    'Jetpack Architecture Components',
    'Room',
    'Hilt',
    'Retrofit',
    'Mavericks',
    'JUnit',
    'Mockito',
    'Espresso',
  ],
  backend: [
    'Python',
    'Ruby',
    'Rails',
    'Rake',
    'RSpec',
    'Django',
    'Flask',
    'Tornado',
    'PostgreSQL',
    'MongoDB',
    'gRPC',
    'Apache Airflow',
  ],
  cloud: [
    'Amazon EC2',
    'Amazon S3',
    'Amazon RDS',
    'Amazon CloudFront',
    'AWS Fargate',
    'AWS Elastic Beanstalk',
  ],
  tools: [
    'Git',
    'Treasure Data',
    'CircleCI',
    'JMeter',
  ],
  system: [
    'Bash',
    'C++',
  ],
};
